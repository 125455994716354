import Vue from 'vue';
import _ from 'lodash';

export default function handleError(error, title, log = true) {

  if (error.response) {
    // The request was made and the server responded with a status code that falls out of the range of 2xx

    const { data, status, config } = error.response;
    let errorMessageBody = 'An error has occurred';

    if (status === 400) {
      errorMessageBody = getMessageBody400(data, config);
    } else if (status === 401) {
      errorMessageBody = '<p>401 Access denied</p>';
    } else if (status === 403) {
      errorMessageBody = '<p>You do not have permission to perform this action</p>';
    } else if (status === 500) {

      if (typeof data === 'string' && data.length > 0) {
        errorMessageBody = data;
      } else {
        errorMessageBody = 'An error occurred on the server (500)';
      }
    }

    Vue.swal({
      title,
      icon: 'error',
      html: errorMessageBody
    });

    if (log) {
      console.error(`Status Code: ${status}; Url:${config.url} Response Data:`, data);
    }

  } else if (error.request) {
    // the server request failed - aka never made it to the back end
    Vue.swal({
      title: 'Request failed',
      icon: 'error',
      text: error.message
    });

    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);

    Vue.swal({
      title,
      icon: 'error',
      text: error.message
    });
  }

  // console.error(error);
};

function getMessageBody400(data, config) {

  let messageBodyHtml = `<div class="error-message-body 400">`;

  if (_.get(data, 'errorType') === 'PostgresConstraintError') {

    const { tableName, constraintName, keys } = data;

    messageBodyHtml += `<p>A record in the table '${tableName}' already exists with this key.</p>`;

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      messageBodyHtml += `<li>${key.key}: ${key.value}</li>`;
    }

    messageBodyHtml += `<p>Constraint: ${constraintName}</p>`;

  }
  if (_.get(data, 'errorType') === 'PostgresDuplicateKeyError') {

    const { errorMessage, tableName, constraintName, keys } = data;

    messageBodyHtml += `<p>${errorMessage}</p>`;

    messageBodyHtml += '<small class=\'text-muted\'>';

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      messageBodyHtml += `<div>${key.key}: ${key.value}</div>`;
    }

    messageBodyHtml += `<div>Constraint: ${constraintName}</div>`;
    messageBodyHtml += `<div>Table: ${tableName}</div>`;

  } else {
    const { errorMessages, errorProperties } = data;

    if (errorMessages && errorMessages.length > 0) {

      messageBodyHtml += `<h3>Error Message(s):</h3>`;
      messageBodyHtml += `<ul>`;

      for (let i = 0; i < errorMessages.length; i++) {
        messageBodyHtml += `<li class="error-message">${errorMessages[i]}</li>`;
      }

      messageBodyHtml += `</ul>`;
    }

    if (errorProperties && errorProperties.length > 0) {

      messageBodyHtml += `<h3>Error Properties:</h3>`;
      messageBodyHtml += `<ul>`;

      for (let i = 0; i < errorProperties.length; i++) {
        messageBodyHtml += `<li class="error-property">${errorProperties[i]}</li>`;
      }

      messageBodyHtml += `</ul>`;
    }

    messageBodyHtml += getApiDetails(config);
  }

  messageBodyHtml += `</div>`;
  return messageBodyHtml;
}

function getApiDetails(config) {
  const { url, method } = config;

  let apiHtml = `<div class="error-message-api">`;

  apiHtml += `<h3>API Details:</h3>`;

  if (url) {
    apiHtml += `<p>Url: ${url}</p>`;
  }

  if (method) {
    apiHtml += `<p>Method: ${method}</p>`;
  }

  apiHtml += `</div>`;
  return apiHtml;
}
