import handleError from '@/errorHandler';
import { ResignationApi } from '@/api/ResignationApi';
import { UserDocumentApi } from '@/api/UserDocumentsApi';
import { ResignationDocumentsApi } from '@/api/ResignationDocumentsApi';
import Vue from 'vue';
import Component from 'vue-class-component';
import { IResignationView, IResignationFileView } from '@/interfaces/_auto/api';


@Component
export default class resignationMixins extends Vue {

  resignationId: number | null = null;
  resignation: IResignationView | null = null;
  documents: IResignationFileView[] = [];
  downloadInProgress = false;


  parseRouteParams() {
    if (this.$route.params.resignationId) {
      const resignationId = Number.parseInt(this.$route.params.resignationId);

      if (!isNaN(resignationId)) {
        this.resignationId = resignationId;
      }
    }
  }

  async getResignation(resignationId) {
    try {
      console.log(`Loading resignation ${resignationId}...`);
      const { data } = await ResignationApi.getResignationById(resignationId);
      this.resignation = data;
      this.resignation.birthMonth--;
      console.log(`Loading resignation ${resignationId}...done`);

      // create empty address object if needed
      if (!this.resignation.address) {
        this.resignation.address = {
          // @ts-ignore
          country: {},
          // @ts-ignore
          stateProvince: {},
          // @ts-ignore
          streetAddress1: null,
          // @ts-ignore
          streetAddress2: null,
          // @ts-ignore
          city: null,
          // @ts-ignore
          postalCode: null,
          // @ts-ignore
          provinceName: null,
        };
      }
    } catch (e) {
      console.error('Failed to load resignation', e);
      handleError(e, 'Failed to load resignation');
    }
  }


  async getFilesForResignation(resignationId) {
    try {
      const { data } = await UserDocumentApi.getUserFileList(resignationId);
      this.documents = data;
    } catch (e) {
      console.error('Failed to load resignation', e);
      handleError(e, 'Failed to load resignation');
    }
  }

  async downloadDocuments() {

    if (!this.resignation) {
      alert('Resignation is null');
      return;
    }

    this.downloadInProgress = true;

    try {

      const { data } = await ResignationDocumentsApi.downloadResignationDocuments(this.resignationId);

      let decoded = atob(data.blobData);
      decoded = this.stringToBytes(decoded);

      const blob = new Blob([decoded], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', data.fileName);
      document.body.appendChild(link);
      link.click();

      this.resignation.documentsDownloadedDate = new Date();
    } catch (e) {
      console.error(e);
      handleError(e, 'Download failed');
    } finally {
      this.downloadInProgress = false;
    }

  }


}
