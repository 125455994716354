import { authAxios } from '@/api';
import { API_VER, ROOT_URL } from '@/app/constants';

const API_CONTROLLER = 'resignation-documents';

export class ResignationDocumentsApi {
  static downloadResignationDocuments(resignationId) {
    const url = `${ROOT_URL}/${API_VER}/${API_CONTROLLER}/download/${resignationId}`;
    return authAxios.get(url);
  }

  static downloadConfirmation(resignationId) {
    const url = `${ROOT_URL}/${API_VER}/${API_CONTROLLER}/confirmation/${resignationId}`;
    return authAxios.get(url);
  }
}
