import { authAxios } from '@/api';
import { API_VER, ROOT_URL } from '@/app/constants';
import { IResignationView, IResignationUpdateRequest, IUserApproveResignationRequest } from '@/interfaces/_auto/api';
import { AxiosPromise } from 'axios';


const API_CONTROLLER = 'resignation';

export class ResignationApi {

  static getResignationListForUser(): AxiosPromise<IResignationView[]> {
    const url = `${ROOT_URL}/${API_VER}/${API_CONTROLLER}/list`;
    return authAxios.get(url);
  }

  static getResignationById(resignationId: number): AxiosPromise<IResignationView> {
    const url = `${ROOT_URL}/${API_VER}/${API_CONTROLLER}/${resignationId}`;
    return authAxios.get(url);
  }

  static AddResignation(updateRequest: IResignationUpdateRequest) {
    const url = `${ROOT_URL}/${API_VER}/${API_CONTROLLER}`;
    return authAxios.post(url, updateRequest);
  }

  static UpdateResignation(resignationId: number, updateRequest: IResignationUpdateRequest) {
    const url = `${ROOT_URL}/${API_VER}/${API_CONTROLLER}/${resignationId}`;
    return authAxios.post(url, updateRequest);
  }

  static getParentListForUser(): AxiosPromise<string[]> {
    const url = `${ROOT_URL}/${API_VER}/${API_CONTROLLER}/parent-list`;
    return authAxios.get(url);
  }

  static userApproveResignation(userApproveResignationRequest: IUserApproveResignationRequest) {
    const url = `${ROOT_URL}/${API_VER}/${API_CONTROLLER}/user-approve`;
    return authAxios.post(url, userApproveResignationRequest);
  }

  static userWithdrawApproval(resignationId: number) {
    const url = `${ROOT_URL}/${API_VER}/${API_CONTROLLER}/user-approve-withdraw/${resignationId}`;
    return authAxios.put(url);
  }

  static deleteResignation(resignationId: number) {
    const url = `${ROOT_URL}/${API_VER}/${API_CONTROLLER}/${resignationId}`;
    return authAxios.delete(url);
  }
}
